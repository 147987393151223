.text-highlight {
  background-color: #f4a570;
  transition: background-color 0.3s ease; /* Smooth transition */
  border-radius: 4px; /* Rounded corners */
  padding: 0 2px; /* Padding around the word */
  display: inline; /* Maintain the inline flow */
}

.settings-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to ensure vertical centering */
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  margin-left: auto;
}
