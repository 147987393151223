body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
  height: 100%;
}
html,
#root {
  height: 100%;
}
.grecaptcha-badge {
  visibility: hidden;
}
